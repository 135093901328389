import axios from "axios";
import {
  IScheduleExchangePriceQuoteReq,
  Uuid,
} from "redmond";

import { config } from "../../config";
import {
  scheduleExchangePriceQuotePath,
} from "../paths";

const scheduleExchangePriceQuote = (
  reservationId: string,
  tripId: string,
  fareId: string,
  subBookingLocator?: string,
): Promise<Uuid> =>
  new Promise(async (resolve, reject) => {
    try {
      const req: IScheduleExchangePriceQuoteReq = {
        reservationId,
        tripId,
        fareId,
        subBookingLocator,
      };
      const res = await axios.post(scheduleExchangePriceQuotePath, req, {
        baseURL: config.baseURL,
      });

      if (res.data.AirExchangePriceQuoteScheduleResponse === "AirExchangePriceQuoteScheduleSuccess") {
        resolve(res.data.sessionId);
      } else {
        reject(res.data.error);
      }
    } catch (e) {
      reject(e);
    }
  });

export default scheduleExchangePriceQuote;
