import axios from "axios";
import { TrackEventRequest } from "redmond";

import { config } from "../../config";
import { postAnalyticEventPath } from "../paths";

export const trackEvent = (req: TrackEventRequest): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(postAnalyticEventPath, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
