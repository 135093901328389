import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionButton,
  BannerSeverity,
  ExpandableCard,
  ExpandableCardContent,
  FlightSummaryRow,
  NotificationBanner,
  formatInterval,
  getTotalPriceText,
  removeTimezone,
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  Icon,
  IconName,
  B2BSpinnerWithText,
  GenericModalContent,
} from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Amount,
  ExchangeActionEnum,
  ExchangeScenario,
  FareDetails,
  FlightShopStep,
  IPartialAirportMap,
  Maybe,
  SelfServeEvents,
  TravelItineraryEnum,
  TripDetails,
  Uuid,
} from "redmond";

import { MobileAirlineDetailsCard } from "../../../MobileAirlineDetailsCard";
import { MobileItineraryDetailsModal } from "../MobileItineraryDetailsModal";
import { ReviewItineraryDetails } from "../ReviewItineraryDetails";
import {
  buttonText,
  confirmCopy,
  flightShopCopy,
  formats,
  ModalState,
  reviewCopy,
  SliceType,
} from "../../../../constants";
import { resetAllFilters, setShopStep } from "../../../../reducers/flightShop";
import {
  getAirportMap,
  getBannerCopy,
  getExchangeScenario,
  getExchangeTypeEvent,
  getExchangeType,
  getShoppedDepartureDate,
  getShoppedReturnDate,
  getShoppedTrip,
  getTripDetails,
  getOriginalExchangeFee,
  getTravelItinerary,
  getFlightBooking,
  getSessionId,
} from "../../../../selectors";
import { ExchangeModuleRootState } from "../../../../store";
import {
  getReviewCardHeader,
  getReviewCardHeaderWithType,
  getSliceIndex,
  getStopsString,
  skipShopAction,
} from "../../../../utils/helpers";
import {
  PATH_FLIGHT_CONFIRM,
  PATH_FLIGHT_CONFIRM_AUTOMATED,
  PATH_FLIGHT_SHOP,
} from "../../../../utils/paths";

import "./styles.scss";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../context/experiments";
import scheduleExchangePriceQuote from "../../../../api/v1/exchange/scheduleExchangePriceQuote";
import { setPriceQuote, setSessionId } from "../../../../reducers/flightBook";
import pollExchangePriceQuote from "../../../../api/v1/exchange/pollExchangePriceQuote";

export interface IReviewItineraryProps extends RouteComponentProps {
  isMobile?: boolean;
}

const defaultProps: Partial<IReviewItineraryProps> = {
  isMobile: false,
};

const ReviewItinerary = (props: IReviewItineraryProps): JSX.Element => {
  const { history, isMobile } = props;
  const isAutomatedExchangeEnabled = useExperiment(
    ActiveExperiments.EnableAutomatedExchange
  );

  const dispatch = useDispatch();
  const airports = useSelector(getAirportMap);
  const bannerCopy = useSelector(getBannerCopy);
  const exchangeType = useSelector(getExchangeType);
  const ogChangeFee = useSelector(getOriginalExchangeFee);
  const scenario = useSelector(getExchangeScenario);
  const departureDate = useSelector(getShoppedDepartureDate);
  const returnDate = useSelector(getShoppedReturnDate);
  const exchangeTypeEvent = useSelector(getExchangeTypeEvent);
  const shoppedTrip = useSelector(getShoppedTrip);
  const tripDetails = useSelector((state: ExchangeModuleRootState) =>
    getTripDetails(state, shoppedTrip.tripId!)
  );
  const booking = useSelector(getFlightBooking);
  const sessionId: Maybe<Uuid> = useSelector(getSessionId);

  const [expandedCard, setExpandedCard] = useState("");
  const [modalOpen, setModalOpen] = useState<SliceType>();
  const [priceQuoteFailureModalOpen, setPriceQuoteFailureModalOpen] =
    useState(false);
  const [failureCount, setFailureCount] = useState(0);
  const [modalState, setModalState] = useState(ModalState.Closed);

  const hideXButtonRef = useRef(true);
  const modalActionsRef = useRef<ReactNode>(null);
  const modalIconRef = useRef<ReactNode>(null);
  const modalSubtitleRef = useRef("");
  const modalTitleRef = useRef("");

  const closeLoadingModal = useCallback(() => {
    setModalState(ModalState.Closed);
  }, []);

  const renderLoadingOrProcessing = (title: string, subtitle: string) => (
    <B2BSpinnerWithText subtitle={subtitle} title={title} />
  );

  const getPriceQuoteLoadingModal = useCallback(() => {
    hideXButtonRef.current = true;
    return renderLoadingOrProcessing(
      confirmCopy.PRICE_QUOTE_LOADING_TITLE,
      confirmCopy.PRICE_QUOTE_LOADING_SUBTITLE
    );
  }, []);

  const onPriceQuoteFailureModalClose = (ev: MouseEvent, reason: string) => {
    if (reason !== "backdropClick") {
      ev?.stopPropagation?.();
      setPriceQuoteFailureModalOpen(false);
      dispatch(resetAllFilters());
      dispatch(setShopStep(FlightShopStep.ChooseDeparture));
      history.push(PATH_FLIGHT_SHOP);
    }
  };

  const PriceQuoteModalContent = getPriceQuoteLoadingModal();
  const originalReservationId = booking!.bookedItinerary.id;

  const { outboundSelection, returnSelection } = exchangeType;
  const selectedFareId = shoppedTrip.returnFareId || shoppedTrip.outgoingFareId;
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );

  const travelItinerary = useSelector(getTravelItinerary)?.TravelItinerary;
  const isMultiTravelItinerary =
    travelItinerary === TravelItineraryEnum.MultiTravelItinerary;

  useEffect(() => {
    trackEvent({
      eventName: SelfServeEvents.ViewedExchangeReviewPage,
      properties: {
        url: window.location.pathname,
        exchange_type: exchangeTypeEvent,
        exchange_fee: ogChangeFee.amount,
      },
    });
  }, [exchangeTypeEvent]);

  // Poll price quote after schedule updates sessionId
  useEffect(() => {
    if (sessionId) {
      pollPriceQuote();
    }
  }, [sessionId]);

  const newPaxPricing = useMemo(() => {
    const pricePerTraveler: Amount = {
      amount: 0,
      currency: "USD",
    };

    if (tripDetails) {
      const { fareDetails } = tripDetails;

      // iterate over departure & return and sum up totals
      pricePerTraveler.amount = fareDetails.reduce((sum, fd) => {
        if (fd.paxPricings?.length) {
          const { currencyCode = "USD", value = 0 } =
            fd.paxPricings[0].pricing.total?.fiat ?? {};

          pricePerTraveler.currency = currencyCode;
          // TODO - consider passenger types?
          sum += value;
        }

        return sum;
      }, 0);

      // prevent negative values if new ticket is less expensive than old ticket
      if (pricePerTraveler.amount < 0) pricePerTraveler.amount = 0;
    }

    return pricePerTraveler;
  }, [tripDetails]);

  const setFlightShopStep = (nextStep: FlightShopStep) =>
    dispatch(setShopStep(nextStep));

  const getExpandableCardContent = (
    departure: boolean,
    location: string,
    tripDetails: TripDetails,
    fareDetails: FareDetails,
    sliceDate: Date,
    isHackerFare: boolean,
    isMixedCabinClass: boolean
  ) => {
    const cardContent: ExpandableCardContent = {
      title: renderDesktopFlightSummaryRow(departure, tripDetails, fareDetails),
      expandedTitle: renderExpandedTitle(
        getReviewCardHeader(departure, location, sliceDate, true),
        departure
      ),
      body: (
        <ReviewItineraryDetails
          fareDetails={fareDetails}
          isDeparture={departure}
          isMixedCabinClass={isMixedCabinClass}
          isMobile={isMobile}
          isMultiTicket={isHackerFare}
          tripDetails={tripDetails}
        />
      ),
    };

    return cardContent;
  };

  const handleCardKeyChange = (cardKey: string) => {
    setExpandedCard(cardKey === expandedCard ? "" : cardKey);
  };

  const renderCardHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");

    return (
      <>
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
      </>
    );
  };

  const renderDesktopFlightSummaryRow = (
    isDeparture: boolean,
    tripDetails: TripDetails,
    fareDetails: FareDetails
  ) => {
    const { slices } = tripDetails;
    const sliceIndex = getSliceIndex(isDeparture, tripDetails);
    const tripSlice = slices[sliceIndex];
    const { airlineCode, airlineName, flightNumber } =
      tripSlice.segmentDetails[0];

    const fareDetailsIndex = getSliceIndex(isDeparture, fareDetails);
    const fareDetailsSlice = fareDetails.slices[fareDetailsIndex];
    const paxPricing = fareDetailsSlice?.paxPricings
      ? fareDetailsSlice.paxPricings[0]
      : undefined;
    const { isBest, isBestQuality, isCheapest, isFastest } =
      fareDetailsSlice.fareScore ?? {};
    const {
      arrivalTime,
      departureTime,
      destinationCode,
      originCode,
      stops,
      totalDurationMinutes,
    } = tripSlice;

    return (
      <>
        <FlightSummaryRow
          airline={airlineName}
          airlineCode={airlineCode}
          arrivalCode={destinationCode}
          arrivalTime={dayjs(removeTimezone(arrivalTime)).format(
            formats.SUMMARY_TIME
          )}
          bestFlightText={isBest ? flightShopCopy.BEST_FLIGHT : undefined}
          bestQualityText={
            isBestQuality ? flightShopCopy.BEST_QUALITY : undefined
          }
          cheapestFlightText={isCheapest ? flightShopCopy.CHEAPEST : undefined}
          className="flight-summary"
          departureCode={originCode}
          departureTime={dayjs(removeTimezone(departureTime)).format(
            formats.SUMMARY_TIME
          )}
          duration={formatInterval(totalDurationMinutes ?? 0)}
          fareClass={fareDetailsSlice.fareShelf?.brandName ?? ""}
          fastestText={isFastest ? flightShopCopy.FASTEST : undefined}
          flightNumber={flightNumber}
          isMobile={isMobile}
          numStops={stops}
          layoverString={getStopsString(stops) || ""}
          price={
            paxPricing
              ? getTotalPriceText({ price: paxPricing.pricing.baseAmount.fiat })
              : ""
          }
        />
        <span className="view-details">View details</span>
      </>
    );
  };

  const renderDesktopReviewContent = useCallback(() => {
    const [departureSlice, returnSlice] = tripDetails.slices;
    const { destinationCode: depDestCode, destinationName: depDestName } =
      departureSlice;
    const departureLocation = airports[depDestCode]?.cityName ?? depDestName;
    const departureRemoved =
      outboundSelection.ExchangeAction === ExchangeActionEnum.remove;
    const returnRemoved =
      returnSelection?.ExchangeAction === ExchangeActionEnum.remove;
    let returnLocation = "";

    if (returnSlice) {
      const { destinationCode: retDestCode, destinationName: retDestName } =
        returnSlice;

      returnLocation = airports[retDestCode]?.cityName ?? retDestName;
    }

    return (
      <Box className="itinerary-cards-section">
        {fareDetails && (
          <>
            {!departureRemoved && departureDate && (
              <Box className="itinerary-card-container departure">
                {expandedCard !== SliceType.departure && (
                  <Typography className="card-header">
                    {renderCardHeader(
                      getReviewCardHeader(
                        true,
                        departureLocation,
                        departureDate!.toDate(),
                        true
                      )
                    )}
                  </Typography>
                )}
                <ExpandableCard
                  aria-expanded={expandedCard === SliceType.departure}
                  cardKey={SliceType.departure}
                  className="itinerary-card b2b"
                  content={getExpandableCardContent(
                    true,
                    departureLocation,
                    tripDetails,
                    fareDetails,
                    departureDate.toDate(),
                    false,
                    false
                  )}
                  expandedCardKey={expandedCard}
                  handleCardKeyChange={() =>
                    handleCardKeyChange(SliceType.departure)
                  }
                  isMobile={isMobile}
                />
              </Box>
            )}
            {!returnRemoved && returnDate && returnLocation && (
              <Box className="itinerary-card-container return">
                {expandedCard !== SliceType.return && (
                  <Typography className="card-header">
                    {renderCardHeader(
                      getReviewCardHeader(
                        false,
                        returnLocation,
                        returnDate.toDate(),
                        true
                      )
                    )}
                  </Typography>
                )}
                <ExpandableCard
                  aria-expanded={expandedCard === SliceType.return}
                  cardKey={SliceType.return as string}
                  className="itinerary-card b2b"
                  content={getExpandableCardContent(
                    false,
                    returnLocation,
                    tripDetails,
                    fareDetails,
                    returnDate.toDate(),
                    false,
                    false
                  )}
                  expandedCardKey={expandedCard}
                  handleCardKeyChange={() =>
                    handleCardKeyChange(SliceType.return)
                  }
                  isMobile={isMobile}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard, isMobile, tripDetails]);

  const renderExpandedTitle = useCallback(
    (expandedTitle: string, isDeparture: boolean) => {
      const blockChange = isDeparture
        ? skipShopAction(outboundSelection)
        : skipShopAction(returnSelection);

      return (
        <Box className="expanded-title-wrapper">
          <Typography className="expanded-title">
            {renderCardHeader(expandedTitle)}
          </Typography>
          {!blockChange && (
            <div className="expanded-title-actions-wrapper">
              <Button
                className="change-button b2b"
                onClick={() => {
                  if (isDeparture) {
                    dispatch(resetAllFilters());
                  }
                  setFlightShopStep(
                    isDeparture
                      ? FlightShopStep.ChooseDeparture
                      : FlightShopStep.ChooseReturn
                  );
                }}
              >
                {buttonText.CHANGE_FLIGHT}
              </Button>
            </div>
          )}
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderMobileFlightSummaryRow = (
    isDeparture: boolean,
    tripDetails: TripDetails,
    date: dayjs.Dayjs,
    airports: IPartialAirportMap,
    isMixedClass: boolean
  ) => {
    const sliceIdx = getSliceIndex(isDeparture, tripDetails);
    const {
      arrivalTime,
      departureTime,
      destinationCode,
      destinationName,
      segmentDetails,
      stops,
      totalDurationMinutes,
    } = tripDetails.slices[sliceIdx];
    const location = airports[destinationCode]?.cityName ?? destinationName;
    const { description, type } = getReviewCardHeaderWithType(
      isDeparture,
      location,
      date
    );

    return (
      <MobileAirlineDetailsCard
        arrivalTime={removeTimezone(arrivalTime)}
        departureTime={removeTimezone(departureTime)}
        description={description}
        duration={totalDurationMinutes ?? 0}
        firstTripSegment={segmentDetails[0]}
        isDeparture={isDeparture}
        isMixedClass={isMixedClass}
        onClick={setModalOpen}
        stops={stops}
        type={type}
      />
    );
  };

  const renderMobileReviewContent = () => {
    const departureRemoved =
      outboundSelection.ExchangeAction === ExchangeActionEnum.remove;
    const returnRemoved =
      returnSelection?.ExchangeAction === ExchangeActionEnum.remove;

    return (
      <Box className="mobile-itinerary-cards-section">
        {!departureRemoved && departureDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileFlightSummaryRow(
              true,
              tripDetails,
              departureDate,
              airports,
              false
            )}
          </Box>
        )}
        {!returnRemoved && returnDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileFlightSummaryRow(
              false,
              tripDetails,
              returnDate,
              airports,
              false
            )}
          </Box>
        )}
      </Box>
    );
  };

  const schedulePriceQuote = async (tripId: string, fareId: string) => {
    setModalState(ModalState.PriceQuoteLoadingOrProcessing);

    return scheduleExchangePriceQuote(originalReservationId, tripId, fareId)
      .then((sessId) => {
        dispatch(setSessionId(sessId));
      })
      .catch(() => {
        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangePriceQuoteFailure
              : SelfServeEvents.ExchangePriceQuoteFailure,
          properties: {
            url: window.location.pathname,
            exchange_type: exchangeTypeEvent,
            exchange_fee: ogChangeFee.amount,
          },
        });

        modalIconRef.current = (
          <Icon className="failure-icon" name={IconName.ErrorState} />
        );
        modalTitleRef.current = confirmCopy.ISSUE_SUBMITTING_REQ;
        modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
        modalActionsRef.current = (
          <ActionButton
            className="contact-support-btn"
            message={buttonText.TRY_AGAIN}
            onClick={() => {
              setPriceQuoteFailureModalOpen(false);
              schedulePriceQuote(tripId, fareId);
            }}
          />
        );
        setPriceQuoteFailureModalOpen(true);
      })
      .finally(() => {});
  };

  const pollPriceQuote = async () => {
    return pollExchangePriceQuote(sessionId!)
      .then((pollQuoteResponse) => {
        if (pollQuoteResponse.priceQuote) {
          dispatch(setPriceQuote(pollQuoteResponse.priceQuote));
          history.push({
            pathname: PATH_FLIGHT_CONFIRM_AUTOMATED,
            search: history.location.search,
          });
        }
      })
      .catch(() => {
        setFailureCount(failureCount + 1);

        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangePriceQuoteFailure
              : SelfServeEvents.ExchangePriceQuoteFailure,
          properties: {
            url: window.location.pathname,
            exchange_type: exchangeTypeEvent,
            exchange_fee: ogChangeFee.amount,
          },
        });

        // Unless price quote is updated to have a better chance of succeeding on a retry,
        // we should direct to manual agent fulfillment after a single failure instead of retrying
        if (failureCount < 0) {
          modalIconRef.current = (
            <Icon className="failure-icon" name={IconName.ErrorState} />
          );
          modalTitleRef.current = confirmCopy.ISSUE_UPDATING_PRICE;
          modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
          modalActionsRef.current = (
            // TODO - this should have the second button in another column
            <>
              <ActionButton
                className="contact-support-btn"
                message={buttonText.TRY_AGAIN}
                onClick={() => {
                  setPriceQuoteFailureModalOpen(false);
                  schedulePriceQuote(shoppedTrip.tripId!, selectedFareId!);
                }}
              />
              <ActionButton
                className="contact-support-btn"
                message={buttonText.SEARCH_AGAIN}
                onClick={() => {
                  setPriceQuoteFailureModalOpen(false);
                  dispatch(resetAllFilters());
                  dispatch(setShopStep(FlightShopStep.ChooseDeparture));
                  history.push(PATH_FLIGHT_SHOP);
                }}
              />
            </>
          );
        } else {
          // After set number of failures we should direct to the manual agent fulfillment
          history.push({
            pathname: PATH_FLIGHT_CONFIRM,
            search: history.location.search,
          });
        }
        setPriceQuoteFailureModalOpen(true);
      })
      .finally(() => {
        closeLoadingModal();
      });
  };

  return (
    <Box className={clsx("review-itinerary-root", { mobile: isMobile })}>
      {isMobile && (
        <Box className="mobile-review-copy">
          <Typography className="review-title">
            {flightShopCopy.REVIEW_ITINERARY_TITLE}
          </Typography>
          <Typography className="review-subtitle">
            {flightShopCopy.REVIEW_ITINERARY_SUBTITLE}
          </Typography>
        </Box>
      )}
      {scenario === ExchangeScenario.ftc && bannerCopy?.body.length ? (
        <NotificationBanner
          html={bannerCopy.body.join(". ")}
          severity={BannerSeverity.NOTICE}
        />
      ) : (
        <NotificationBanner
          html={reviewCopy.PAX_PRICING_DIFF(newPaxPricing)}
          severity={BannerSeverity.WARNING}
        />
      )}
      <Box className="review-itinerary-container">
        {isMobile ? renderMobileReviewContent() : renderDesktopReviewContent()}
      </Box>
      {/* Price Quote Loading Modal */}
      {isMobile ? (
        <MobilePopoverCard
          centered
          className="flight-exchange-mobile-modal"
          contentClassName="modal-content"
          onClose={() => {
            return;
          }}
          open={modalState == ModalState.PriceQuoteLoadingOrProcessing}
          topRightButton={
            hideXButtonRef.current ? undefined : (
              <ActionLink
                className="close-mobile-modal-btn"
                content={<CloseButtonIcon />}
                label="Close"
                onClick={closeLoadingModal}
              />
            )
          }
        >
          {PriceQuoteModalContent}
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          className="flight-exchange-desktop-modal"
          hideXButton={hideXButtonRef.current}
          invisibleBackdrop={false}
          onClose={() => {
            return;
          }}
          open={modalState == ModalState.PriceQuoteLoadingOrProcessing}
        >
          {PriceQuoteModalContent}
        </DesktopPopupModal>
      )}
      {/* Price Quote failure modal */}
      {priceQuoteFailureModalOpen && isMobile ? (
        <MobilePopoverCard
          centered
          disableEscapeKeyDown
          className="submit-request-response-modal mobile"
          contentClassName="modal-content"
          onClose={onPriceQuoteFailureModalClose}
          open={priceQuoteFailureModalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          disableEscapeKeyDown
          invisibleBackdrop={false}
          className="submit-request-response-modal"
          onClose={onPriceQuoteFailureModalClose}
          open={priceQuoteFailureModalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </DesktopPopupModal>
      )}
      <Box className="continue-btn-container">
        <Box className="total-price-copy">
          <Typography
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: reviewCopy.NEW_PAX_PRICING(newPaxPricing),
            }}
          />
          <Typography className="subtitle">
            {reviewCopy.NEW_PAX_SUBTITLE(!!shoppedTrip.returnSliceId)}
          </Typography>
        </Box>
        <ActionButton
          ariaLabelText="Continue Button"
          className="continue-btn"
          message="Continue"
          onClick={() => {
            if (isAutomatedExchangeEnabled && !isMultiTravelItinerary) {
              schedulePriceQuote(shoppedTrip.tripId!, selectedFareId!);
            } else {
              history.push({
                pathname: PATH_FLIGHT_CONFIRM,
                search: history.location.search,
              });
            }
          }}
        />
      </Box>
      <MobileItineraryDetailsModal
        fareDetails={fareDetails}
        isDeparture={modalOpen === SliceType.departure}
        isMixedCabinClass={false}
        onClose={() => setModalOpen(undefined)}
        open={Boolean(modalOpen)}
        tripDetails={tripDetails}
      />
    </Box>
  );
};

ReviewItinerary.defaultProps = defaultProps;

export default withRouter(ReviewItinerary);
