import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CreditCard, IExchangePriceQuote, Maybe, RewardsPrice, Uuid} from "redmond";

import {resetState} from "./search";

export interface FlightBookState {
  sessionId: Maybe<Uuid>;
  priceQuote: Maybe<IExchangePriceQuote>;
  paymentMethods: CreditCard[];
  rewardsPrice: Maybe<RewardsPrice>;
}

export const initialPriceQuote: IExchangePriceQuote = {
  reservationId: "",
  totalPricing: {
    baseAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    passengerFeeAmount: 0,
    currencyCode: "",
  },
  records: [],
}

const initialState: FlightBookState = {
  sessionId: "",
  priceQuote: initialPriceQuote,
  paymentMethods: [],
  rewardsPrice: null,
};

export const FlightBookSlice = createSlice({
  initialState,
  name: "exchange/flightBook",
  reducers: {
    setExchangeBook(
      state: FlightBookState,
      action: PayloadAction<Uuid>
    ) {
      state.sessionId = action.payload;
    },
    setSessionId: (
      state: FlightBookState,
      action: PayloadAction<Uuid>
    ) => {
      state.sessionId = action.payload;
    },
    setPriceQuote: (
      state: FlightBookState,
      action: PayloadAction<IExchangePriceQuote>
    ) => {
      state.priceQuote = action.payload;
    },
    setPaymentMethods: (
      state: FlightBookState,
      action: PayloadAction<CreditCard[]>
    ) => {
      state.paymentMethods = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, () => initialState)
      .addDefaultCase((_state, _action) => {});
  },
});

export const {
  setExchangeBook,
  setSessionId,
  setPriceQuote,
  setPaymentMethods,
} = FlightBookSlice.actions;

export default FlightBookSlice.reducer;
