import axios from "axios";
import {
  IPollExchangePriceQuoteReq,
  IPollExchangePriceQuoteRes,
  Uuid,
} from "redmond";

import { config } from "../../config";
import {
  pollExchangePriceQuotePath,
} from "../paths";

const pollExchangePriceQuote = (
  sessionId: Uuid,
): Promise<IPollExchangePriceQuoteRes> => {
  let intervalId: number;

  return new Promise((resolve, reject) => {
    if (!sessionId) reject("Session ID is required");
    intervalId = window.setInterval(async () => {
      try {
        const req: IPollExchangePriceQuoteReq = {
          sessionId,
        };
        const pollRes = await axios.put<IPollExchangePriceQuoteRes>(pollExchangePriceQuotePath, req, {
          baseURL: config.baseURL,
        });

        if (pollRes.data.AirExchangePriceQuotePollResponse === "AirExchangePriceQuotePollSuccess") {
          clearInterval(intervalId)
          return resolve(pollRes.data);

        } else if (pollRes.data.AirExchangePriceQuotePollResponse !== "AirExchangePriceQuotePollPending") {
          clearInterval(intervalId)
          return reject(pollRes.data.AirExchangePriceQuotePollResponse);
        }

      } catch (err) {
        clearInterval(intervalId);
      }
    }, 3000);
  });
}

export default pollExchangePriceQuote;
