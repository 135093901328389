import {ExchangeModuleRootState} from "../store";

export const getFlightBook = (state: ExchangeModuleRootState) =>
  state.flightBook;

export const getSessionId = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).sessionId;
};

export const getPriceQuote = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).priceQuote;
};

export const getPaymentMethods = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).paymentMethods;
};
