import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import {
  ActionButton,
  ActionLink,
  B2BSpinnerWithText,
  CloseButtonIcon,
  CurrencyFormatters,
  DesktopPopupModal,
  GenericModalContent,
  Icon,
  IconName,
  ImportantInfoList,
  MobilePopoverCard,
  removeTimezone,
} from "halifax";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  CfarEvents,
  CreditCard,
  ExchangeActionEnum,
  ExchangeScenario,
  FlightShopStep,
  IExchangePriceQuote,
  Maybe,
  RewardsAccount,
  SelfServeEvents,
  Uuid,
} from "redmond";

import { trackEvent } from "../../api/v1/analytics/trackEvent";
import {
  buttonText,
  confirmCopy,
  ModalState,
  SliceType,
  TripType,
} from "../../constants";
import {
  getAirportMap,
  getConfirmPageCopy,
  getExchangeScenario,
  getExchangeType,
  getExchangeTypeEvent,
  getFlightBooking,
  getFlights,
  getOriginalExchangeFee,
  getPaymentMethods,
  getPolicyHasCfar,
  getPriceQuote,
  getRewardsAccounts,
  getSessionId,
  getShoppedDepartureDate,
  getShoppedReturnDate,
  getShoppedTrip,
  getTripDetails,
} from "../../selectors";
import { ExchangeModuleRootState } from "../../store";
import {
  getReviewCardHeaderWithType,
  getSliceIndex,
} from "../../utils/helpers";
import { PATH_FLIGHT_EXCHANGE, TRIPS_HOME } from "../../utils/paths";
import { MobileItineraryDetailsModal } from "../FlightReshop/components/MobileItineraryDetailsModal";
import { MobileAirlineDetailsCard } from "../MobileAirlineDetailsCard";
import { CheckoutBreakdown } from "./components/CheckoutBreakdown";
import { FlightSummaryCard } from "./components/FlightSummaryCard";

import "./styles.scss";
import {
  initialPriceQuote,
  setPaymentMethods,
  setPriceQuote,
  setSessionId,
} from "../../reducers/flightBook";
import scheduleExchangeBook from "../../api/v1/exchange/scheduleExchangeBookFinalize";
import pollExchangeBook from "../../api/v1/exchange/pollExchangeBook";
import { PaymentOpaqueValue } from "@b2bportal/purchase-api/lib/api";
import { getItinerarySlices } from "../../utils/flightSlices";
import { CipherText } from "@b2bportal/purchase-api";
import ExchangeUserPayments from "./userPayments";
import { FiatAmountV2 } from "redmond/build/apis/tysons/payment-machine";
import {
  IListPaymentMethodsResponse,
  listPaymentMethods,
} from "../../api/v1/payments/listPaymentMethods";
import { setShopStep } from "../../reducers/flightShop";

export interface IConfirmFlightExchangeProps extends RouteComponentProps {
  isMobile: boolean;
}

const defaultProps: Partial<IConfirmFlightExchangeProps> = {
  isMobile: false,
};

const ConfirmFlightExchangeAutomated = (
  props: IConfirmFlightExchangeProps
): JSX.Element => {
  const { history, isMobile } = props;
  const dispatch = useDispatch();
  const sessionId: Maybe<Uuid> = useSelector(getSessionId);

  const modalActionsRef = useRef<ReactNode>(null);
  const modalIconRef = useRef<ReactNode>(null);
  const modalSubtitleRef = useRef("");
  const modalTitleRef = useRef("");
  const totalPriceRef = useRef("");

  const airports = useSelector(getAirportMap);
  const booking = useSelector(getFlightBooking);
  const confirmPageCopy = useSelector(getConfirmPageCopy);
  const departureDate = useSelector(getShoppedDepartureDate);
  const exchangeType = useSelector(getExchangeType);
  const exchangeTypeEvent = useSelector(getExchangeTypeEvent);
  const flights = useSelector(getFlights);
  const hasCfar = useSelector(getPolicyHasCfar);
  const ogChangeFee = useSelector(getOriginalExchangeFee);
  const returnDate = useSelector(getShoppedReturnDate);
  const scenario = useSelector(getExchangeScenario);
  const shoppedTrip = useSelector(getShoppedTrip);
  const tripDetails = useSelector((state: ExchangeModuleRootState) =>
    getTripDetails(state, shoppedTrip.tripId!)
  );

  const rewards: RewardsAccount[] = useSelector(getRewardsAccounts);
  const cardPaymentMethods: CreditCard[] = useSelector(getPaymentMethods);

  const fetchPaymentMethods = () => {
    const paymentMethods = listPaymentMethods();

    paymentMethods
      .then((paymentMethods: IListPaymentMethodsResponse) => {
        dispatch(setPaymentMethods(paymentMethods));
        setEnableBookFinalizeButton(true);
      })
      .catch(() => {
        modalIconRef.current = (
          <Icon className="failure-icon" name={IconName.ErrorState} />
        );
        modalTitleRef.current = confirmCopy.ISSUE_SUBMITTING_REQ;
        modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
        modalActionsRef.current = (
          <ActionButton
            className="contact-support-btn"
            message={buttonText.TRY_AGAIN}
            onClick={() => {
              setModalOpen(false);
              fetchPaymentMethods();
            }}
          />
        );
        setModalOpen(true);
      });
  };

  const resetFlightBookState = () => {
    dispatch(setSessionId(""));
    dispatch(setPriceQuote(initialPriceQuote));
    dispatch(setShopStep(FlightShopStep.ChooseDeparture));
  };

  const priceQuote: Maybe<IExchangePriceQuote> = useSelector(getPriceQuote);

  const [enableBookFinalizeButton, setEnableBookFinalizeButton] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileSummaryOpen, setMobileSummaryOpen] = useState<SliceType>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(ModalState.Closed);
  const hideXButtonRef = useRef(true);

  const { outboundSelection, returnSelection } = exchangeType;
  const departureRemoved =
    outboundSelection.ExchangeAction === ExchangeActionEnum.remove;
  const returnRemoved =
    returnSelection?.ExchangeAction === ExchangeActionEnum.remove;
  const selectedFareId = shoppedTrip.returnFareId || shoppedTrip.outgoingFareId;
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );

  const closeLoadingModal = useCallback(() => {
    setModalState(ModalState.Closed);
  }, []);

  const renderLoadingOrProcessing = (title: string, subtitle: string) => (
    <B2BSpinnerWithText subtitle={subtitle} title={title} />
  );

  const getBookFinalizeLoadingModal = useCallback(() => {
    hideXButtonRef.current = true;
    return renderLoadingOrProcessing(
      confirmCopy.BOOK_FINALIZE_LOADING_TITLE,
      confirmCopy.BOOK_FINALIZE_LOADING_SUBTITLE
    );
  }, []);

  const FinalizeBookModalContent = getBookFinalizeLoadingModal();

  const { outgoingSlice, returnSlice } = useMemo(() => {
    return getItinerarySlices(
      shoppedTrip,
      outboundSelection,
      returnSelection,
      booking,
      flights!.slices
    );
  }, [flights, outboundSelection, returnSelection, shoppedTrip]);

  const onModalClose = (ev: MouseEvent, reason: string) => {
    if (reason !== "backdropClick") {
      ev?.stopPropagation?.();
      setModalOpen(false);

      history.push({
        pathname: TRIPS_HOME,
        search: `?tripId=${booking?.bookedItinerary.id ?? ""}`,
      });
    }
  };

  const renderMobileAirlineCard = (
    isDeparture: boolean,
    date: dayjs.Dayjs,
    isMixedClass: boolean
  ) => {
    const sliceIdx = getSliceIndex(isDeparture, tripDetails);
    const {
      arrivalTime,
      departureTime,
      destinationCode,
      destinationName,
      segmentDetails,
      stops,
      totalDurationMinutes,
    } = tripDetails.slices[sliceIdx];
    const location = airports[destinationCode]?.cityName ?? destinationName;
    const sliceType = isDeparture ? SliceType.departure : SliceType.return;
    const { description, type } = getReviewCardHeaderWithType(
      isDeparture,
      location,
      date
    );

    return (
      <MobileAirlineDetailsCard
        arrivalTime={removeTimezone(arrivalTime)}
        departureTime={removeTimezone(departureTime)}
        description={description}
        duration={totalDurationMinutes ?? 0}
        firstTripSegment={segmentDetails[0]}
        isDeparture={isDeparture}
        isMixedClass={isMixedClass}
        onClick={() => setMobileSummaryOpen(sliceType)}
        stops={stops}
        type={type}
      />
    );
  };

  const scheduleBookFinalize = async () => {
    setIsSubmitting(true);
    setModalState(ModalState.BookFinalizeLoadingOrProcessing);

    const totalPaymentAmount = priceQuote!.totalPricing.totalAmount || 0;
    const currency = priceQuote!.totalPricing.currencyCode || "USD";
    const totalPaymentFiatAmount: FiatAmountV2 = {
      amount: totalPaymentAmount,
      currency: currency,
    };

    const userPaymentProps = {
      totalPaymentFiatAmount,
      paymentMethods: cardPaymentMethods,
      rewards,
    };

    const payments: Promise<Array<PaymentOpaqueValue>> =
      ExchangeUserPayments(userPaymentProps);

    payments.then((payments) => {
      return scheduleExchangeBook(
        sessionId!,
        payments,
        booking!.bookedItinerary.id
      )
        .then((response) => {
          pollBookFinalize(response.token);
        })
        .catch(() => {
          trackEvent({
            eventName:
              scenario === ExchangeScenario.ftc
                ? SelfServeEvents.FTCExchangeBookFailure
                : SelfServeEvents.ExchangeBookFailure,
            properties: {
              url: window.location.pathname,
              exchange_type: exchangeTypeEvent,
              exchange_fee: ogChangeFee.amount,
            },
          });

          modalIconRef.current = (
            <Icon className="failure-icon" name={IconName.ErrorState} />
          );
          modalTitleRef.current = confirmCopy.ISSUE_SUBMITTING_REQ;
          modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
          modalActionsRef.current = (
            <ActionButton
              className="contact-support-btn"
              message={buttonText.TRY_AGAIN}
              onClick={() => {
                setIsSubmitting(false);
                setModalOpen(false);
                scheduleBookFinalize();
              }}
            />
          );
          setModalOpen(true);
        })
        .finally(() => {});
    });
  };

  const pollBookFinalize = async (token: CipherText) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    let agentLocator = booking?.bookedItinerary.travelItinerary.locators?.agent
    let completedRequestSelfServeProperties = {
      success: false,
      rebook_eligibile: true, 
      reshop_eligibile: true,
      agent_locator_provider: agentLocator?.provider,
      agent_locator: agentLocator?.value,
      request_type: scenario === ExchangeScenario.ftc ? 'ftc_exchange' : 'exchange'
    }
    return pollExchangeBook(sessionId!, token)
      .then((response) => {
        console.log("Polling response " + response);
        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangeBookSuccess
              : SelfServeEvents.ExchangeBookSuccess,
          properties: {
            url: window.location.pathname,
            exchange_fee: ogChangeFee.amount,
            exchange_type: exchangeTypeEvent,
          },
        });

        let automationSucceeded = response.AirExchangeBookPollResponse === "AirExchangeBookPollSuccess"
        completedRequestSelfServeProperties.success = automationSucceeded
        trackEvent({
          eventName: SelfServeEvents.CompletedRequestSelfServe,
          properties: completedRequestSelfServeProperties,
        })

        if (hasCfar && ogChangeFee) {
          const { amount, currency } = ogChangeFee;

          trackEvent({
            eventName: CfarEvents.RebookingFeeWaived,
            properties: {
              agent_locator:
                booking?.bookedItinerary.travelItinerary.locators?.agent,
              originalChangeFee:
                CurrencyFormatters.get(currency).format(amount),
            },
          });
        }

        modalTitleRef.current = confirmCopy.EXCHANGE_BOOK_SUCCESS_TITLE;
        modalSubtitleRef.current = confirmCopy.EXCHANGE_BOOK_SUCCESS_SUBTITLE;

        modalActionsRef.current = (
          <ActionButton
            className="done-btn"
            message={buttonText.DONE}
            onClick={onModalClose as any}
          />
        );
        modalIconRef.current = (
          <Icon className="success-icon" name={IconName.Checked} />
        );
      })
      .catch(() => {
        trackEvent({
          eventName:
            scenario === ExchangeScenario.ftc
              ? SelfServeEvents.FTCExchangeBookFailure
              : SelfServeEvents.ExchangeBookFailure,
          properties: {
            url: window.location.pathname,
            exchange_type: exchangeTypeEvent,
            exchange_fee: ogChangeFee.amount,
          },
        });

        trackEvent({
          eventName: SelfServeEvents.CompletedRequestSelfServe,
          properties: completedRequestSelfServeProperties,
        })

        modalIconRef.current = (
          <Icon className="failure-icon" name={IconName.ErrorState} />
        );
        modalTitleRef.current = confirmCopy.ISSUE_SUBMITTING_REQ;
        modalSubtitleRef.current = confirmCopy.SUBMIT_TRY_AGAIN;
        // We need to schedule a new Price Quote to be able to try again if the polling returns a failure,
        // so we can't simply call scheduleBookFinalize() again here.
        resetFlightBookState();
      })
      .finally(() => {
        closeLoadingModal();
        setIsSubmitting(false);
        setModalOpen(true);
        resetFlightBookState();
      });
  };

  const ExchangeHeader = useMemo(
    () => (
      <Box className="copy-header">
        <Typography className="review-title">
          {confirmPageCopy?.title ?? confirmCopy.REVIEW_TITLE}
        </Typography>
        {confirmPageCopy?.body.map((subtitle: string) => (
          <Typography
            className="review-subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            key={subtitle}
          />
        ))}
      </Box>
    ),
    [confirmPageCopy]
  );

  // reroute back to landing page if booking isn't set
  useEffect(() => {
    if (!booking) {
      history.push({
        pathname: PATH_FLIGHT_EXCHANGE,
        search: history.location.search,
      });
    }
  }, []);

  // fetch user payment methods on page load
  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    trackEvent({
      eventName:
        scenario === ExchangeScenario.ftc
          ? SelfServeEvents.ViewedFTCExchangeConfirmPage
          : SelfServeEvents.ViewedExchangeConfirmPage,
      properties: {
        url: window.location.pathname,
        exchange_type: exchangeTypeEvent,
        exchange_fee: ogChangeFee.amount,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile ? (
    <Box className="mobile-confirm-flight-exchange">
      {ExchangeHeader}
      <MobilePopoverCard
        centered
        className="flight-exchange-mobile-modal"
        contentClassName="modal-content"
        onClose={() => {
          return;
        }}
        open={modalState == ModalState.BookFinalizeLoadingOrProcessing}
        topRightButton={
          hideXButtonRef.current ? undefined : (
            <ActionLink
              className="close-mobile-modal-btn"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={closeLoadingModal}
            />
          )
        }
      >
        {FinalizeBookModalContent}
      </MobilePopoverCard>
      <Box className="itinerary-cards-section">
        {!departureRemoved && departureDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileAirlineCard(true, departureDate, false)}
          </Box>
        )}
        {!returnRemoved && returnDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileAirlineCard(false, returnDate, false)}
          </Box>
        )}
      </Box>
      {confirmPageCopy?.importantInfo?.length ? (
        <ImportantInfoList
          ordered
          infoItems={confirmPageCopy.importantInfo}
          title={confirmCopy.IMPORTANT_INFO}
        />
      ) : null}
      <CheckoutBreakdown
        isMobile
        isSubmitting={isSubmitting}
        onSubmit={scheduleBookFinalize}
        totalPriceRef={totalPriceRef}
        submitButtonText={buttonText.CONFIRM_AND_EXCHANGE}
        enableSubmitButton={enableBookFinalizeButton}
        priceQuote={priceQuote}
      />
      <MobileItineraryDetailsModal
        fareDetails={fareDetails}
        isDeparture={mobileSummaryOpen === SliceType.departure}
        isMixedCabinClass={false}
        onClose={() => setMobileSummaryOpen(undefined)}
        open={Boolean(mobileSummaryOpen)}
        tripDetails={tripDetails}
      />
      {modalOpen && (
        <MobilePopoverCard
          centered
          disableEscapeKeyDown
          className="submit-request-response-modal mobile"
          contentClassName="modal-content"
          onClose={onModalClose}
          open={modalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </MobilePopoverCard>
      )}
    </Box>
  ) : (
    <Box className="confirm-flight-exchange-root">
      <Box className="exchange-summary">
        {ExchangeHeader}
        <DesktopPopupModal
          className="flight-exchange-desktop-modal"
          hideXButton={hideXButtonRef.current}
          invisibleBackdrop={false}
          onClose={() => {
            return;
          }}
          open={modalState == ModalState.BookFinalizeLoadingOrProcessing}
        >
          {FinalizeBookModalContent}
        </DesktopPopupModal>
        <Box className="shopped-trip-overview">
          {outgoingSlice && (
            <FlightSummaryCard
              className="outbound"
              isMobile={isMobile}
              key="outbound-slice"
              slice={outgoingSlice}
              tripType={TripType.Outbound}
            />
          )}
          {returnSlice && (
            <FlightSummaryCard
              className="return"
              isMobile={isMobile}
              key="return-slice"
              slice={returnSlice}
              tripType={TripType.Return}
            />
          )}
        </Box>
        <CheckoutBreakdown
          isSubmitting={isSubmitting}
          onSubmit={scheduleBookFinalize}
          totalPriceRef={totalPriceRef}
          submitButtonText={buttonText.CONFIRM_AND_EXCHANGE}
          enableSubmitButton={enableBookFinalizeButton}
          priceQuote={priceQuote}
        />
        {confirmPageCopy?.importantInfo?.length ? (
          <ImportantInfoList
            ordered
            infoItems={confirmPageCopy.importantInfo}
            title={confirmCopy.IMPORTANT_INFO}
          />
        ) : null}
      </Box>
      {modalOpen && (
        <DesktopPopupModal
          disableEscapeKeyDown
          invisibleBackdrop={false}
          className="submit-request-response-modal"
          onClose={onModalClose}
          open={modalOpen}
        >
          <GenericModalContent
            actions={modalActionsRef.current}
            image={modalIconRef.current}
            subtitle={modalSubtitleRef.current}
            title={modalTitleRef.current}
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};

ConfirmFlightExchangeAutomated.defaultProps = defaultProps;

export default withRouter(ConfirmFlightExchangeAutomated);
