import React from "react";
import { Box } from "@material-ui/core";
import { FlightGridRow } from "halifax";
import { TripCategory } from "redmond";

import {
  FlightListItemInfoContent,
  IFlightListItemInfoContentProps,
} from "../FlightListItemInfoContent";
import { ISkeletonFlightListItemProps } from "../SkeletonFlightListItem";
import { FlightCardType } from "../../../../constants";

export interface IFlightListItemInfoProps
  extends IFlightListItemInfoContentProps,
    ISkeletonFlightListItemProps {}

const defaultProps: Partial<IFlightListItemInfoProps> = {};

const FlightListItemInfo = (props: IFlightListItemInfoProps): JSX.Element => {
  const { type } = props;

  if (type === FlightCardType.content) {
    return <FlightListItemInfoContent {...props} />;
  } else if (type === FlightCardType.skeleton) {
    const skeletonProps = {
      ...props,
      airlineCode: "",
      airlineName: "",
      arrivalTime: "",
      departureTime: "",
      destinationCode: "",
      duration: "",
      fares: {
        basic: null,
        standard: null,
        enhanced: null,
        premium: null,
        luxury: null,
      },
      tripType: TripCategory.ROUND_TRIP,
      onClick: () => {},
      onFareClick: () => {},
      originCode: "",
      layoverString: "",
    };

    return (
      <Box className="flight-list-info-root">
        <FlightGridRow {...skeletonProps} />
      </Box>
    );
  }

  return <></>;
};

FlightListItemInfo.defaultProps = defaultProps;

export default FlightListItemInfo;
